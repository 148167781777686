import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/auth-services/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  	title = 'app';

  	constructor(private auth: AuthService) {
  	}

  	ngOnInit() {
  		//this.auth.getCurrentUser();
  	}
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../shared/auth-services/index';


@Injectable()
export class ClientGuard implements CanActivate {
  constructor(
    private auth: AuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.hasCustomerRole()) {
      return true;
    } else {
      alert('You don\'t have permission to view this page');
      return false;
    }
  }
}

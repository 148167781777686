import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService, User} from '../shared/auth-services';

@Injectable({
  providedIn: 'root'
})
export class RoleCheckGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private myRoute: Router,
    private currentUser: User) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles = route.data.allowedRoles as Array<string>;
    const roleCodes = this.currentUser.info.permissions;
    let hasRole = false;
    for (let i = 0; i < roleCodes.length; i++) {
      if (allowedRoles.includes(roleCodes[i].code)) {
        hasRole = true;
        break;
      }
    }
    if (hasRole) {
      return true;
    } else {
      alert('You don\'t have permission to view this page');
      return false;
    }
  }
}

import { Component, AfterViewInit, EventEmitter, Output, Input } from '@angular/core';
import { User, AuthService, EconApi } from '../auth-services/index';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import swal from 'sweetalert2';
import {NgForm} from '@angular/forms';
import {ClientDataService} from '../client-data/client-data.service';
declare var $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']

})
export class NavigationComponent implements AfterViewInit {
  @Input() result = '';
  @Output() clicked= new EventEmitter<string>();
  @Output() toggleSidebar = new EventEmitter<void>();
  userInfo: any;
  email: any;
  customerList: any;
  selectedPersonId: any;
  toast = swal.mixin({toast: true,position: 'top-end',showConfirmButton: false,timer: 3000});
  timeout: any;
  showLoader= false;

  public config: PerfectScrollbarConfigInterface = {};
  constructor(private modalService: NgbModal, public currentUser: User, public auth: AuthService, private api: EconApi, private route: Router, private clientService: ClientDataService) {
    this.userInfo = currentUser;
    api.getCustomerList().then(data => {
      this.customerList = data;
    }, err => {
      alert('Could not load customer list');
    });
  }

  search(){
    if(this.selectedPersonId){
      this.route.navigate(['/clients/client-details/' + this.selectedPersonId]);
      this.selectedPersonId = null;
      this.showSearch = false;
    }
  }

  public showSearch = false;

  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  ngAfterViewInit() {}

  	logout() {
    	localStorage.removeItem("api_token");
    	this.route.navigate(['/authentication/login'])
  	}

  setPassword() {
    swal({
      title: 'Password Reset',
      html:
        'New Password: <input id="swal-input1" class="swal2-input" style="width:50%;" placeholder="Password" type="password" ><br/>' +
        'Confirm Password: <input id="swal-input2" class="swal2-input" style="width:50%;" placeholder="Confirm password" type="password">',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      confirmButtonColor: 'orange',
      showLoaderOnConfirm: true,
      preConfirm: (result) => {
        const input1 = (document.getElementById('swal-input1') as HTMLInputElement).value;
        const input2 = (document.getElementById('swal-input2') as HTMLInputElement).value;
        if (result && input1 && input2) {
          if (input1 === input2) {
            const pattern = new RegExp('^.*(?=.{4,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\d\\X])(?=.*[!@#$%^&\\*\\(\\)_+\\-=\\{\\}\\[\\];\':,\\.<>\x22\x20\x60\x7E\\?\\/]).*$');
            let patternPass = 1;
            if (!pattern.test(input1)) {
              patternPass = 0;
            }
            if (!pattern.test(input2)) {
              patternPass = 0;
            }
            if (input1.length < 6) {
              patternPass = 0;
            }
            if (!patternPass) {
              swal.showValidationMessage(
                'Your password must contain: <br/>' +
                'at least 6 characters<br/>' +
                'at least 1 lower case letter<br/>' +
                'at least 1 upper case letter<br/>' +
                'at least 1 numeric value and<br/>' +
                'at least 1 special character<br/>');
            }
            return [input1, input2];
          } else {
            swal.showValidationMessage('Passwords don\'t match. Try again');
          }
        } else {
          swal.showValidationMessage('Enter and confirm password before submission');
          return false;
        }

      }
    }).then(data => {
      if (data.dismiss) {
        // do nothing
      } else {
        this.currentUser.info.password = data.value[0];
        this.api.setPassword(this.currentUser.info).then(data => {
          this.toast({type: 'success', title: 'Successfully updated password'});
        }, err => {
          this.toast({type: 'error', title: 'Unable to updated password'});
        });
      }
    });
  }

  onClick(searchTerm: string) {
    this.showLoader = true;
    this.clientService.filterClients(searchTerm);
    this.showLoader = false;
  }
}

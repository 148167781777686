import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService, User } from '../shared/auth-services/index';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private myRoute: Router,
    private currentUser: User) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.hasRole('ADMIN') ||
      this.auth.hasRole('LOANOFFICER') ||
      this.auth.hasRole('PROCESSOR') ||
      this.auth.hasRole('ASSISTANT') ||
      this.auth.hasRole('SUPERADMIN')) {
      return true;
    } else {
      alert('You don\'t have permission to view this page');
      return false;
    }
  }
}

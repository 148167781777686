import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService, User} from '../shared/auth-services';

@Injectable({
  providedIn: 'root'
})
export class AssistantGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private myRoute: Router,
        private currentUser: User) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.auth.hasRole('ASSISTANT')) {
            return true;
        } else {
            alert('You don\'t have permission to view this page');
            return false;
        }
    }
}

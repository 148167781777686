import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EconApi} from '../../../shared/auth-services';
import swal from 'sweetalert2';


@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css'],
  providers: [NgbActiveModal]
})
export class AddClientComponent implements OnInit {

  public clientForm: FormGroup;
  public toast = swal.mixin({toast: true, position: 'top-end', showConfirmButton: false, timer: 1000});

  constructor(public modalService: NgbModal,
            public activeModal: NgbActiveModal,
            private fb: FormBuilder,
            private api: EconApi) {
  this.clientForm = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.email, Validators.required]],
  });
  }

  ngOnInit() {
  }
  openModal(content) {
    this.activeModal = this.modalService.open(content, {centered: true,  backdrop : 'static'});
  }
  saveData () {
    this.api.createNewClientFromDashboard(this.clientForm.value).then(data => {
      if (!data['status']) {
        this.toast({type: 'error', title: data['details']['message']});
      }else {
        this.toast({type: 'success', title: data['details']['message']});
        this.closeModal();
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
    }, err => {
      alert('uh oh...');
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  hasError(control) {
    const formControl = this.clientForm.controls[control];
    if (!formControl.touched) {
      return false;
    }
    return formControl.invalid;
  }

  checkFieldWithErrorType(control, errorType = 'required') {
    const formControl = this.clientForm.controls[control];
    if (!formControl.touched) {
      return false;
    }
    if (formControl.hasError(errorType)) {
      return true;
    }
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { User } from '../shared/auth-services/index';
import { Router } from '@angular/router';


@Injectable()
export class ResetpasswordGuard implements CanActivate {
  constructor(
    private myRoute: Router,
    private currentUser: User){
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // console.log(this.currentUser.info);
    // return true;
    if(this.currentUser && this.currentUser.info.must_change_password == false){
      return true;
    }else{
      this.myRoute.navigate(["/authentication/login"]);
      return false;
    }
  }
}

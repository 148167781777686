import {Component, AfterViewInit, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ROUTES} from './menu-items';
import {RouteInfo} from './sidebar.metadata';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService, User} from '../../shared/auth-services/index';
import * as _ from 'lodash';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    userInfo: any;
    showMenu = '';
    showSubMenu = '';
    public sidebarnavItems: any[];

    // this is for the open close
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    addActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        } else {
            this.showSubMenu = element;
        }
    }

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private route: ActivatedRoute,
        private currentUser: User,
        private auth: AuthService
    ) {
        this.userInfo = currentUser;
    }

    // End open close
    ngOnInit() {
      const navItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
      const  IS_SUPERADMIN = this.auth.hasRole('SUPERADMIN');
      const  IS_ADMIN = this.auth.hasRole('ADMIN');
      const  IS_LO = this.auth.hasRole('LOANOFFICER');
      const  IS_LOA = this.auth.hasRole('ASSISTANT');
      const  IS_PROCESSOR = this.auth.hasRole('PROCESSOR');
      const  IS_CUSTOMER = this.auth.hasCustomerRole();

      /**
       * Even the user has the more than one role, the nav items with the highest role is only shown
       */
      for (let i = 0; i < navItems.length; i++) {
        if (IS_SUPERADMIN) {
          if (navItems[i].title === 'Contacts') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Loan Summary') {_.remove(navItems, navItems[i]); }
        } else if (IS_ADMIN) {
          if (navItems[i].title === 'Dashboard') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Notifications Manager') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Contacts') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Loan Summary') {_.remove(navItems, navItems[i]); }
        } else if (IS_LO || IS_LOA || IS_PROCESSOR) {
          if (navItems[i].title === 'Notifications Manager') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Contacts') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Loan Summary') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Clients') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Website Manager') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Historical Rates') {_.remove(navItems, navItems[i]); }
          if (navItems[i].title === 'Internal Users') {_.remove(navItems, navItems[i]); }
        } else {
          if (IS_CUSTOMER) {
            if (navItems[i].title === 'Notifications Manager') {_.remove(navItems, navItems[i]); }
            if (navItems[i].title === 'Clients') {_.remove(navItems, navItems[i]); }
            if (navItems[i].title === 'Website Manager') {_.remove(navItems, navItems[i]); }
            if (navItems[i].title === 'Historical Rates') {_.remove(navItems, navItems[i]); }
            if (navItems[i].title === 'Internal Users') {_.remove(navItems, navItems[i]); }
          }
        }
        }
      this.sidebarnavItems = navItems;
    }
}

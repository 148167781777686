import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AuthService} from './auth-service';
import {catchError, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {EconApi} from './econ-api.service';

@Injectable()
export class AuthenticatedResponseInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private api: EconApi) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const requestUrl = this.api.baseUrl + '/login';
          let errorMessage = 'Something went wrong';
          if (error.status === 401 && requestUrl !== error.url) {
            this.authenticationService.logout();
            errorMessage = error.statusText;
            location.reload(true);
          } else {
            if (error.error !== undefined) {
              errorMessage = error.error;
            } else {
              errorMessage = error.statusText;
            }
          }
          if (error.status === 500) {
            errorMessage = 'Internal server error';
          }
          if (error.status === 404) {
            if (error.error !== undefined) {
              errorMessage = error.error;
            } else {
              errorMessage = error.statusText;
            }
          }

          if (error.status === 422 && error.error !== undefined && error.error.errors !== undefined) {
            const errors = error.error.errors;
            for (const errorList in errors) {
              if (errors[errorList].length) {
                errorMessage = errors[errorList][0];
              }
            }
          }
          return throwError({error: errorMessage});
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { EconApi } from '../auth-services';
import * as _ from 'lodash';
import { Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {

  customerList: any;
  objectKeys = Object.keys;
  lodash = _;
  email: any;
  public _uploadSubject: BehaviorSubject<any> = new BehaviorSubject(null);


  constructor(private api: EconApi, private http: HttpClient) { }

  filterClients(value) {
      this._uploadSubject.next(value);
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService, User } from '../shared/auth-services/index';
import { Router } from '@angular/router';
import * as _ from 'lodash';


@Injectable()
export class AdminGuard implements CanActivate {
    constructor(
    	private auth: AuthService,
       	private myRoute: Router,
       	private currentUser: User){
    }
    canActivate(
       	next: ActivatedRouteSnapshot,
       	state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
           	if(_.includes(this.currentUser.permissions, 'SUPERADMIN') || _.includes(this.currentUser.permissions, 'ADMIN')){
           		return true;
           	}else{
           		alert("You don't have permission to view this page");
           		return false;
           	}
    }
}
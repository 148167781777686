import {Component, Input, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  @Input() layout;
  pageInfo;
  public isVisible = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe(event => {
        this.titleService.setTitle(event['title']);
        this.pageInfo = event;
      });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const pathName = window.location.pathname;
        const validPaths = [
          '/clients',
          '/dashboard/loan-officer-dashboard',
          '/dashboard/processor-dashboard',
          '/dashboard/assistant-dashboard',
        ];
        if (validPaths.includes(pathName)) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
      }
    });
  }
  ngOnInit() {}
}

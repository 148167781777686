import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {AuthService, User} from '../shared/auth-services/index';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private myRoute: Router,
        private currentUser: User) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.auth.hasRole('SUPERADMIN')) {
          this.myRoute.navigate(['/dashboard/super-admin-dashboard']);
          return true;
        } else if (this.auth.hasRole('ADMIN')) {
            this.myRoute.navigate(['/clients']);
            return true;
        }else if (this.auth.hasRole('LOANOFFICER')) {
            this.myRoute.navigate(['/dashboard/loan-officer-dashboard']);
            return true;
        }else if (this.auth.hasRole('PROCESSOR')) {
            this.myRoute.navigate(['/dashboard/processor-dashboard']);
            return true;
        }else if (this.auth.hasRole('ASSISTANT')) {
            this.myRoute.navigate(['/dashboard/assistant-dashboard']);
            return true;
        }else if (this.auth.hasRole('CUST')) {
          this.myRoute.navigate(['/dashboard/client-dashboard']);
          return true;
        }
    }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService, User } from '../shared/auth-services/index';
import { Router } from '@angular/router';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
    	private auth: AuthService,
       	private myRoute: Router,
       	private currentUser: User){
    }
    canActivate(
       	next: ActivatedRouteSnapshot,
       	state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
           	if(this.auth.isLoggednIn()){
                return true;
           	}else{
                this.myRoute.navigate(["/authentication/login"]);
                return false;
           	}
    }
}

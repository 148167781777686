import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AuthGuard, AdminGuard, ResetpasswordGuard, RoleGuard, SuperAdminGuard, RoleCheckGuard} from './guards/index';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

export const Approutes: Routes = [
    {
        path: '',
        component: FullComponent,
        children: [
            { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthGuard] },
            { path: 'dashboard', loadChildren: './dashboards/dashboard.module#DashboardModule', canActivate: [AuthGuard, ResetpasswordGuard] },
             { path: 'clients', loadChildren: './clients/clients.module#ClientsModule', canActivate: [AuthGuard, RoleGuard] },
            { path: 'notifications-manager', loadChildren: './notifications-manager/notifications-manager.module#NotificationsManagerModule', canActivate: [AuthGuard, SuperAdminGuard] },
            { path: 'website-manager', loadChildren: './website-manager/website-manager.module#WebsiteManagerModule', data: {allowedRoles: ['SUPERADMIN', 'ADMIN']}, canActivate: [AuthGuard, RoleCheckGuard] },
            { path: 'line-graph', loadChildren: './line-graph/line-graph.module#LineGraphModule', data: {allowedRoles: ['SUPERADMIN', 'ADMIN']}, canActivate: [AuthGuard, RoleCheckGuard] },
            { path: 'contacts', loadChildren: './contacts/contacts.module#ContactsModule', canActivate: [AuthGuard] },
            { path: 'internal-users', loadChildren: './loan-officers/loan-officers.module#LoanOfficersModule', data: {allowedRoles: ['SUPERADMIN', 'ADMIN']}, canActivate: [AuthGuard, RoleCheckGuard]},
            { path: 'loan-summary', loadChildren: './loan-summary/loan-summary.module#LoanSummaryModule', canActivate: [AuthGuard] },

        ]
    },
    {
        path: '',
        component: BlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:
                    './authentication/authentication.module#AuthenticationModule'
            },
          // { path: 'clients', loadChildren: './clients/clients.module#ClientsModule', canActivate: [AuthGuard, RoleGuard] },
        ]
    },
    {
        path: '**',
        redirectTo: '/authentication/404'
    }
];

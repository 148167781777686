import { Injectable } from "@angular/core";
import { Http, Headers, RequestOptions } from "@angular/http";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
//import { Router } from '@angular/router';

import "rxjs";

@Injectable()
export class EconApi {
  public baseUrl = "";

  constructor(public http: HttpClient) {
    if (location.hostname == "portal.econmortgage.com") {
      this.baseUrl = "https://api.econmortgage.com/api";
    } else if (location.hostname == "portal-staging.econmortgage.com") {
      this.baseUrl = "https://staging-api.econmortgage.com/api";
    } else {
      // this.baseUrl = 'http://127.0.0.1:8000/api';
      this.baseUrl = "https://econmortgage-api-v2.localhost/api";
    }
  }

  login(credentials) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/login", credentials).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  verify(token) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/verify-passcode", token).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  smsCode(email) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/sms-code", { email }).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }
  register(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/register", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getCurrentUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/current-user").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  setPassword(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/set-password", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  userGetInfo(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/user/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getLoanOfficers() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/loan-officers").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  portalLoanOfficers() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/user-loan-officers-list").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  loadProcessors() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/processors").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  loanOfficerAssistants() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/loan-officer-assistants").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getPossibleMilestones(type) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrl + "/get-possible-milestones/" + type)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  getUsersCompletedMilestones(id) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrl + "/get-users-completed-milestones/" + id)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  getDocumentList() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/document-list").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getUserDocumentList(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/user-document-list/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  clientAssignedDocs(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/client-assigned-docs/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  documentTypes() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/document-types").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  refinanceMilestones() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/get-refinance-milestones").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  purchaseMilestones() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/get-purchase-milestones").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getCustomerList() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/customer-list").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getFilteredCustomerList(email) {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/customer-list/" + email).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  loadClient(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/user/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getDocumentNotificationEmails() {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrl + "/get-document-notification-emails")
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  getConsentNotificationEmails() {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrl + "/get-consent-notification-emails")
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  getBlogPosts() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/manage-blog-posts").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getReviews() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/manage-reviews").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getTeamMembers() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/manage-team-members").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  uploadDocument(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/upload-document", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  setClientType(id, data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/set-client-type/" + id, data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  mustChangePassword(id, data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/set-must-change-password/" + id, data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateCompletedMilestones(id, data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-completed-milestones/" + id, data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  requireDocUser(docUser) {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseUrl + "/require-document-user", docUser).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateDocType(docType) {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseUrl + "/document-type", docType).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  recoverPassword(obj) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/recover-password", obj).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  resetPassword(obj) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/reset-password", obj).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  setLoanOfficer(obj) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/set-loan-officer/" + obj.clientId, obj)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  setLoanOfficerAssistant(obj) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/set-loan-officer-assistant/" + obj.clientId, obj)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  setProcessor(obj) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/set-processor/" + obj.clientId, obj)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateRefiMilestone(refiMilestone) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-refinance-milestone", refiMilestone)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  updatePurchaseMilestone(milestone) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-purchase-milestone", milestone)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  adminSetSubmit(docUser) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/document-admin-set-submit", docUser)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  acceptAndRejectMultiple(docArray) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/document-accept-reject", docArray)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  assignTypes(id, types) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/document-types/" + id, types).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateDocumentNotificationEmail(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-document-notification-email", data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  removeDocumentNotificationEmail(data) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(this.baseUrl + "/remove-document-notification-email/" + data.id)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateConsentNotificationEmail(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-consent-notification-email", data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  removeConsentNotificationEmail(data) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(this.baseUrl + "/remove-consent-notification-email/" + data.id)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  addNewDocumentNotificationEmail(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/add-new-document-notification-email", data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  addNewConsentNotificationEmail(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/add-new-consent-notification-email", data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  createDocType(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/document-type", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  createRefiMilestone(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/create-refinance-milestone", data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  createPurchaseMilestone(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/create-purchase-milestone", data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  publishPost(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/publish-blog-post/" + id, {}).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  unPublishPost(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/unpublish-blog-post/" + id, {}).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  publishReview(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/publish-review/" + id, {}).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  unPublishReview(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/unpublish-review/" + id, {}).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  updatePost(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-blog-post/" + data.id, data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateReview(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-review/" + data.id, data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateTeamMember(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-team-member/" + data.id, data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  employeeImage(id, data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/employee-image/" + id, data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  newReview(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/new-review", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  newPost(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/new-blog-post", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  newTeamMember(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/new-team-member", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  checkEmployeeImage(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/team-image-check", data).subscribe(
        (response) => resolve(response),
        (err) => {
          reject(err);
        }
      );
    });
  }

  docsNeededReminder(id) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/admin-docs-needed-reminder/" + id, {})
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteDocument(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.baseUrl + "/delete-document/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteBlogPost(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.baseUrl + "/delete-blog-post/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteTeamMember(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.baseUrl + "/delete-team-member/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteReview(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.baseUrl + "/delete-review/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteUserDoc(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.baseUrl + "/document-user/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteUser(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.baseUrl + "/admin-delete-user/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteDocType(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.baseUrl + "/document-type/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteRefiMilestone(id) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(this.baseUrl + "/delete-refinance-milestone/" + id)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  deletePurchaseMilestone(id) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(this.baseUrl + "/delete-purchase-milestone/" + id)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateUserDocNote(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/document-user-note/" + data.id, data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  addOrUpdateUserDocNote(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/document-user-note", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  deleteUserDocNote(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.baseUrl + "/document-user-note/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  toggleReminderEmail(clientInfo) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/toggle-reminder-email", clientInfo)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  uploadExcelFile(fileToUpload: File) {
    return new Promise((resolve, reject) => {
      const formData: FormData = new FormData();
      formData.append("excel_file", fileToUpload, fileToUpload.name);
      this.http.post(this.baseUrl + "/upload-excel-file", formData).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getGraphData(filter) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/list-excel-file", filter).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllExcelData() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/get-all-excel-data").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateExcelData(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-excel-data/" + data.id, data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  newExcelData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/add-excel-data", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getUserLoanOfficers() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/get-loan-officers").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  submitLoanOfficer(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/create-loan-officer", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateLoanOfficer(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/update-loan-officer/" + data.id, data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  deleteLoanOfficer(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.baseUrl + "/delete-loan-officer/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  loadLoanOfficer(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/get-loan-officer/" + id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAssignedCustomerList(type, email) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrl + "/assigned-customer-list/" + type + "/" + email)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  getUnassignedCustomerList(type, email) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrl + "/unassigned-customer-list/" + type + "/" + email)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  getContactList(user_id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/get-contact-list/" + user_id).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getTokenForCalyxApi() {
    const apiUrl = "https://econm123api.pulsedashboard.net/token";
    const params = new HttpParams()
      .set("username", "ECONM123@focusitinc.com")
      .set("grant_type", "password")
      .set("password", "ZprFk6AvF8cbwWQP");

    return this.http.post(apiUrl, params.toString());
  }

  getMappedFieldValues(pointRecordId) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrl + "/get-mapped-fields/" + pointRecordId)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  updatePointRecord(userId, obj) {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.baseUrl + "/update-point-record/" + userId, obj)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateClientEmail(obj) {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.baseUrl + "/update-client-email/" + obj.id, obj)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }

  getRoleList() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/get-role-list").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getDefaultNotes() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/get-default-notes").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getIncompleteCustomerList() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/website/customer-list/").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  uploadRefisOnlyData(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/upload-refis-only-data", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getIncompleteCustomerDetails(id) {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + `/website/customer/${id}/details`).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  getRefisOnlyData() {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseUrl + "/get-all-refis-only-data").subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  createNewClientFromDashboard(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseUrl + "/create-customer", data).subscribe(
        (res) => resolve(res),
        (err) => {
          reject(err);
        }
      );
    });
  }

  toggleEmailNotifications(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + "/toggle-email-notifications", data)
        .subscribe(
          (res) => resolve(res),
          (err) => {
            reject(err);
          }
        );
    });
  }
}

import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/notifications-manager',
    title: 'Notifications Manager',
    icon: 'mdi mdi-email',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/clients',
    title: 'Clients',
    icon: 'mdi mdi-account-multiple',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/website-manager',
    title: 'Website Manager',
    icon: 'mdi mdi-web',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/line-graph',
    title: 'Historical Rates',
    icon: 'mdi mdi-chart-line',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/internal-users',
    title: 'Internal Users',
    icon: 'mdi mdi-account-multiple-outline',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/contacts',
    title: 'Contacts',
    icon: 'mdi mdi-contacts',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/loan-summary',
    title: 'Loan Summary',
    icon: 'mdi mdi-menu',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  }
];

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  CommonModule
} from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { AuthService, User, EconApi, TokenInterceptor } from './shared/auth-services/index';
import { ClientDataService } from './shared/client-data/client-data.service';
import { AuthGuard, AdminGuard, ClientGuard, ResetpasswordGuard, SuperAdminGuard} from './guards/index';
import { NgSelectModule } from '@ng-select/ng-select';
import {AuthenticatedResponseInterceptor} from './shared/auth-services/authenticated-response.interceptor';
import {AddClientComponent} from './clients/components/add-client/add-client.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

export function init(auth: AuthService) {
    return () => auth.getCurrentUser();
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    AddClientComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes),
    PerfectScrollbarModule,
    NgSelectModule
  ],
  providers: [
    AuthService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },{
        provide: APP_INITIALIZER,
        useFactory: init,
        deps: [AuthService],
        multi: true
    },{
       provide: HTTP_INTERCEPTORS,
       useClass: TokenInterceptor,
       multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticatedResponseInterceptor,
      multi: true
    },
    EconApi, User, AuthGuard, AdminGuard, ClientGuard, ResetpasswordGuard, ClientDataService, SuperAdminGuard
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}

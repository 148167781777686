import {Injectable} from '@angular/core';
import {EconApi} from './econ-api.service';
import {promise} from 'selenium-webdriver';
import * as _ from 'lodash';


@Injectable()
export class User {
  info: any = {};
  apiToken: any;
  permissions: any = [];
}

@Injectable()
export class AuthService {

  constructor(private api: EconApi, private user: User) {
  }

  login(credentials) {
    return new Promise((resolve, reject) => {
      this.api.login(credentials).then(data => {
        if (!data['code_required']) {
          this.user.info = data['user'];
          this.user.apiToken = data['api_token'];
          this.user.permissions = _.map(this.user.info.permissions, 'code');
          localStorage.setItem('api_token', data['api_token']);
          localStorage.setItem('temp', '1');
        }
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  verify(token) {
    return new Promise((resolve, reject) => {
      this.api.verify(token).then(data => {
        this.user.info = data['user'];
        this.user.apiToken = data['api_token'];
        this.user.permissions = _.map(this.user.info.permissions, 'code');
        localStorage.setItem('api_token', data['api_token']);
        localStorage.setItem('temp', '1');
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }
  
  smsCode(email){
    return this.api.smsCode(email);
  }

  isLoggednIn() {
    return localStorage.getItem('api_token') !== null;
  }

  getCurrentUser() {
    if (window.location.pathname != '/authentication/login') {
      return new Promise((resolve, reject) => {
        this.api.getCurrentUser().then(data => {
          if (data) {
            this.user.info = data;
            this.user.permissions = _.map(this.user.info.permissions, 'code');
            this.user.apiToken = localStorage.getItem('api_token');
            resolve();
          }
        }, err => {
          this.logout();
          window.location.href = 'authentication/login';
          reject();
        });
      });
    } else {
      return null;
    }
  }

  logout() {
    localStorage.removeItem('api_token');
    window.location.href = 'authentication/login';
  }

  /**
   * Check if the user has a role based on input role code
   * If no roles is assigned to user, then it return false
   * otherwise, it checks for the role to present in the role lists
   * @param checkRoleCode
   */
  hasRole(checkRoleCode: string): boolean {
    const roleLists = this.user.permissions;
    if (roleLists.length <= 0) {
      return false;
    }
    const role = roleLists.find(r => r === checkRoleCode);
    if (role === undefined) {
      return false;
    }

    return true;
  }

  /**
   * Checks if the customer user has a customer role
   */

  hasCustomerRole(): boolean {
    const roleLists = this.user.permissions;
    if (roleLists.length <= 0) {
      return false;
    }
    const CustomerRoleCode = 'CUST';
    const role = roleLists.find(r => r === CustomerRoleCode);
    if (role === undefined) {
      return false;
    }
    return true;
  }
}
